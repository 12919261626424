import React, { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Typography,
} from "@mui/material";
import PropertyInfo from "./property-info";
import Media from "./media";
import Review from "./review";
import Publish from "./publish";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { callPostApi, callPostApiWithAuth } from "utils/api";
import { useSnackbar } from "components/common/custom-notification/CustomNotification";
import UploadMedia from "./upload-media";
import imageCompression from "browser-image-compression";
import CustomModal from "components/common/custom-modal/CustomModal";
const steps = ["Property info", "Media", "Upload Media", "Review", "Publish"];

const StepForm: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const params = useParams();
  const [formData, setFormData] = useState({
    name: "",
    baths: "",
    bedrooms: "",
    parking_areas: "",
    status: "active",
    description: "",
    pool: false,
    price: "",
    imagesUrl: [] as File[],
  });

  const [errors, setErrors] = useState({
    name: false,
    baths: false,
    bedrooms: false,
    parking_areas: false,
    description: false,
    price: false,
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const editData = location?.state?.editData;
  const [showModal, setShowModal] = useState(false);
  console.log("Received edit data:", editData);
  useEffect(() => {
    if (editData) {
      setFormData((prevData) => ({
        ...prevData,
        name: editData.name,
        baths: editData.baths,
        bedrooms: editData.bedrooms,
        parking_areas: editData.parking_areas,
        status: editData.status ,
        description: editData.description,
        pool: editData.pool,
        price: editData.price,
        imagesUrl: editData.imagesUrl,
      }));
    }
  }, [editData]);

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      baths: formData.baths == '0' ? false :  !formData.baths,
      bedrooms: formData.bedrooms == '0' ? false :  !formData.bedrooms,
      parking_areas: formData.parking_areas == '0' ? false :  !formData.parking_areas,
      description: !formData.description,
      price: !formData.price,
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };
  console.log("error", errors)
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    // @ts-ignore
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      if (validateForm()) {
        setLoading(true);

        const authState = localStorage.getItem("authState");
        let token = "";

        if (authState) {
          const parsedAuthState = JSON.parse(authState);
          token = parsedAuthState?.token || "";
        }
        callPostApiWithAuth(
          `/properties/update?id=${params?.id}`,
          formData,
          () => {
            showSnackbar("Property Listing Successful!", "success");
            navigate("/");
            setLoading(false);
          },
          () => {
            showSnackbar("Failed to List Property", "error");
            setLoading(false);
          },
          token
        );
      } else {
        console.log("Validation failed. Please fill out all required fields.");
      }
    } else if (activeStep === 2) {
      setLoading(true);
      await uploadImagesInChunks(formData.imagesUrl);
      setLoading(false);
      showSnackbar("Images Deployed Successfully!", "success");
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      if(editData && activeStep==0){
        setShowModal(true);
      }else{
        setActiveStep((prevStep) => prevStep + 1);
      }
      
    }
  };
  const handleModalClose = (editImages: boolean) => {
    setShowModal(false);

    if (editImages) {
      setFormData((prevData) => ({
        ...prevData,
        imagesUrl: [] as File[], 
      }));
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      setActiveStep((prevStep) => prevStep + 3);
    }
  };
  const uploadImagesInChunks = async (imagesUrl: File[]) => {
    const chunkSize = 3;
    const totalChunks = Math.ceil(imagesUrl.length / chunkSize);
    const uploadedUrls: string[] = [];

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const chunk = imagesUrl.slice(
        chunkIndex * chunkSize,
        (chunkIndex + 1) * chunkSize
      );
      const compressedFiles = await Promise.all(
        chunk.map(handleImageCompression)
      );
      console.log("compressedFiles", compressedFiles, chunk);
      const urls = await uploadChunk(compressedFiles);
      uploadedUrls.push(...urls);
      if (chunkIndex === 0 && urls.length > 0) {
        setFormData((prevData) => ({
          ...prevData,
          thumbnailImage: urls[0],
        }));
      }
    }

    setFormData((prevData: any) => ({
      ...prevData,
      imagesUrl: uploadedUrls,
    }));
  };

  const handleImageCompression = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedBlob = await imageCompression(file, options);

      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
      });

      return compressedFile;
    } catch (error) {
      return file;
    }
  };

  const uploadChunk = async (files: File[]): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      const imageFormData = new FormData();
      files.forEach((file) => {
        imageFormData.append("files", file);
      });
      console.log("imagdata", imageFormData);
      const name = formData.name;
      callPostApi(
        `/properties/upload-bucket?name=${name}`,
        imageFormData,
        (data) => {
          resolve(data?.imageUrls);
        },
        (errorMessage) => {
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      name: "",
      baths: "",
      bedrooms: "",
      parking_areas: "",
      status: "active",
      description: "",
      pool: false,
      price: "",
      imagesUrl: [],
    });
  };

  const renderStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <PropertyInfo
            formData={formData}
            handleChange={handleChange}
            onNext={handleNext}
            onBack={handleBack}
            errors={errors}
          />
        );
      case 1:
        return <Media formData={formData} setFormData={setFormData} />;
      case 2:
        return <UploadMedia formData={formData} setFormData={setFormData} />;
      case 3:
        return <Review formData={formData} />;
      case 4:
        return <Publish />;
      default:
        return <div>Unknown step</div>;
    }
  };
  console.log("formyuui", formData);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation={isMobile ? "vertical" : "horizontal"}
        sx={{
          "& .MuiStepConnector-line": {
            borderColor: "#5fa5ffb5",
            borderTopWidth: "4px",
            minWidth: "30px",
          },
          "& .MuiStepIcon-root": {
            borderRadius: "50%",
          },
          "& .MuiStepIcon-text": {
            fill: "#000",
          },
          "& .MuiSvgIcon-root:not(.Mui-completed)": {
            color: "#5fa5ffb5",
          },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <CircularProgress sx={{ mb: 2 }} />
            <Typography variant="h6" color="textSecondary">
              Your files are uploading, please wait until it's done.
            </Typography>
          </Box>
        ) : (
          <>
            {renderStepContent(activeStep)}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                sx={
                  activeStep === 0
                    ? {
                        backgroundColor: "#D3D3D3",
                        "&:hover": {
                          bgcolor: "#D3D3D3",
                        },
                        color: "black",
                      }
                    : {
                        backgroundColor: "#5fa5ff",
                        "&:hover": {
                          bgcolor: "#5fa5ff",
                        },
                      }
                }
                disabled={activeStep === 0 || activeStep > 2}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                sx={{
                  backgroundColor: "#5fa5ff",
                  "&:hover": {
                    bgcolor: "#5fa5ff",
                  },
                }}
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </>
        )}
      </Box>

      <CustomModal open={showModal} onClose={() => handleModalClose(false)}>
        <Typography variant="h6" id="custom-modal-title">
          Edit Images
        </Typography>
        <Typography variant="body1" id="custom-modal-description">
          Do you want to edit the images?
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button sx={{width:'45%'}} variant="contained" color="primary" onClick={() => handleModalClose(true)}>
            Yes
          </Button>
          <Button sx={{width:'45%'}} variant="contained" color="secondary" onClick={() => handleModalClose(false)}>
            No
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default StepForm;
