import { Box } from "components/common";
import Logo from "../../../assets/images/Logo.png";
import { Typography, Skeleton } from "@mui/material";
import LoginForm from "./LoginForm";

export default function LoginPage() {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        pt={1}
        pb={1}
        height="calc(100px - 16px)"
        width="100%"
      >
        <img style={{ width: "60px", height: "60px" }} src={Logo} />
      </Box>
      <Typography
        fontWeight="bold"
        sx={{ color: "#000" }}
        fontSize="28px"
        variant="subtitle1"
      >
        Login
      </Typography>
      <LoginForm />
    </Box>
  );
}
