export const addToLocalStorage = (key: string, data: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error("Error adding to localStorage:", error);
  }
};
export const removeFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error("Error removing from localStorage:", error);
  }
};
export const getFromLocalStorage = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error("Error getting from localStorage:", error);
    return null;
  }
};
export const localStorageEffect =
  (key: string) =>
  ({ setSelf, trigger }: any) => {
    if (trigger === "get") {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        setSelf(JSON.parse(storedValue));
      }
    }

    window.addEventListener("storage", (event: any) => {
      if (event.key === key) {
        setSelf(JSON.parse(event.newValue));
      }
    });

    return () => {
      window.removeEventListener("storage", (event) => {
        if (event.key === key) {
          setSelf(null);
        }
      });
    };
  };
