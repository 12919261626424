import { Yup } from "components/common";
import { FormikValues } from "formik";
import { useSetAuthDataState } from "lib/states/authState";
import { callGetApi } from "utils/api";
import { showLoadingToast, updateToast } from "utils/toast";

const initialValues = {
  email: "",
  password: "",
};

const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Please enter your email address"),
  password: Yup.string()
    .matches(
      passwordRegex,
      "Password must be 6-16 characters long, include at least one uppercase letter, one special character, and one digit"
    )
    .required("Please enter your password"),
});

export default function useAuthFunctions() {
  const setAuthData = useSetAuthDataState();
  const onLoginSubmit = async (values: FormikValues) => {
    const id = showLoadingToast("Please wait...");
    const { email, password } = values;

    const requestPath = `/users/login?email=${encodeURIComponent(
      email
    )}&password=${encodeURIComponent(password)}`;

    callGetApi(
      requestPath,
      (data: any) => {
        updateToast(id, {
          render: "Login successful!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        setAuthData({ token: data.token, isLoggedIn: true });
      },
      (errorMessage: string) => {
        updateToast(id, {
          render: errorMessage || "Login failed",
          type: "error",
          isLoading: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000,
        });
      }
    );
  };

  return { initialValues, validationSchema, onLoginSubmit };
}

// import { Yup } from "components/common";
// import { FormikValues } from "formik";
// import { useSetAuthDataState } from "lib/states/authState";
// import { showLoadingToast, updateToast } from "utils/toast";

// const initialValues = {
//   email: "",
//   password: "",
// };

// const passwordRegex =
//   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email("Enter a valid email")
//     .required("Please enter your email address"),
//   password: Yup.string()
//     .matches(
//       passwordRegex,
//       "Password must be 6-16 characters long, include at least one uppercase letter, one special character, and one digit"
//     )
//     .required("Please enter your password"),
// });

// export default function useAuthFunctions() {
//   const setAuthData = useSetAuthDataState();
//   const onLoginSubmit = async (values: FormikValues) => {
//     const id = showLoadingToast("Please wait...");
//     console.log("data", values);

//     updateToast(id, {
//       render: "Login successful!",
//       type: "success",
//       isLoading: false,
//       autoClose: 5000,
//     });

//     setAuthData({ token: "dummy", isLoggedIn: true });
//     // await axiosInstance
//     //   .get("/users/login", { params: values })
//     //   .then((response) => {
//     //     updateToast(id, {
//     //       render: "Login successful!",
//     //       type: "success",
//     //       isLoading: false,
//     //       autoClose: 5000,
//     //     });
//     //     console.log(response.data);
//     //     setAuthData({ token: response.data.token, isLoggedIn: true });
//     //   })
//     //   .catch((error) => {
//     //     if (error.response) {
//     //       updateToast(id, {
//     //         render: error?.response?.data?.message,
//     //         type: "error",
//     //         isLoading: false,
//     //         closeOnClick: true,
//     //         closeButton: true,
//     //         autoClose: 5000,
//     //       });
//     //     } else {
//     //       updateToast(id, {
//     //         render: "Server Error",
//     //         type: "error",
//     //         isLoading: false,
//     //         closeOnClick: true,
//     //         closeButton: true,
//     //         autoClose: 5000,
//     //       });
//     //     }
//     //   });
//   };
//   return { initialValues, validationSchema, onLoginSubmit };
// }
