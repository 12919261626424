import { useMemo } from "react";

import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { drawerWidth } from "config";
import useFunctions from "../ts/useFunctions";
import DrawerContent from "./drawer-content";
import DrawerHeader from "./drawer-header";

interface MainDrawerProps {
  window?: any;
}

export default function MainDrawer(props: MainDrawerProps) {
  const { window } = props;
  const theme = useTheme();
  const { open, handleDrawerToggle } = useFunctions();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
  const drawerContent = useMemo(() => <DrawerContent />, []);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: open ? drawerWidth : 0,
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",

          "& .MuiDrawer-paper": {
            backgroundImage: "none",
            width: open ? drawerWidth : 0,
            overflowX: "hidden",
            boxSizing: "border-box",
            transitionProperty: "all",
            transitionDuration: "225ms",
            TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
            borderRight: "1px solid #82aac552",
          },
        }}
      >
        {drawerHeader}
        {drawerContent}
      </Drawer>
    </Box>
  );
}
