import { Box } from "components/common";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
//@ts-ignore
import Spline from "@splinetool/react-spline";
export default function AuthedLayout() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          color: "white",
          bgcolor: "#dddddd",
          borderRadius: "20px",
          maxWidth: "450px",
        }}
      >
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
      {/* <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          height: "100vh",
          width: "100vw",
        }}
      >
        <Spline scene="https://prod.spline.design/L9gx72qHY3Ke7Xq2/scene.splinecode" />
      </Box> */}
    </Box>
  );
}
