import { Box, CustomLoadingButton, Form, Formik, Yup } from "components/common";
import LoginEmailAddress from "./LoginEmailAddress";
import LoginPassword from "./LoginPassword";
import { theme } from "theme";
import useAuthFunctions from "../ts/useAuthFunctions";

export default function LoginForm() {
  const { initialValues, validationSchema, onLoginSubmit } = useAuthFunctions();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onLoginSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <LoginEmailAddress />
          <LoginPassword />
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              marginTop: theme.spacing(2),
            }}
          >
            <CustomLoadingButton
              loading={isSubmitting}
              type="submit"
              sx={{
                margin: "auto",
              }}
            >
              Login
            </CustomLoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
