import { useDashboardLoaderState } from "lib/states/dashboardLoaderState";
import { useDrawerState, useSetDrawerState } from "lib/states/drawerState";

export default function useFunctions() {
  const [open] = useDrawerState();
  const setOpen = useSetDrawerState();
  const [loader] = useDashboardLoaderState();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const getUser = () => {
    console.log("getUser");
  };

  return {
    loader,
    open,
    setOpen,
    handleDrawerToggle,
    getUser,
  };
}
