import { Box } from "@mui/material";
import StepForm from "./step-from";

const StepperPage = () => {
  return (
    <Box sx={{ height: "calc(100vh - 170px)", overflowY: "scroll" }}>
      <StepForm />
    </Box>
  );
};
export default StepperPage;
