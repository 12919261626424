import { useTheme } from "@mui/material/styles";
import { Box, Height } from "components/common";

import Logo from "assets/images/Logo.png";
import { AppBar, Skeleton } from "@mui/material";

interface DrawerHeaderProps {
  open: boolean;
}

export default function DrawerHeader(props: DrawerHeaderProps) {
  const { open } = props;
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      pt={1}
      pb={1}
      position="absolute"
      top={0}
      height="calc(100px - 16px)"
      width="100%"
    >
      {/* <Skeleton width={200} /> */}
      <img style={{ width: "60px", height: "60px" }} src={Logo} />
    </Box>
  );
}
