import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { IconButton, IconButtonProps, styled } from "@mui/material";
import { CustomLinkButtonProps, CustomLinkProps } from "utils/interface";
import { Link, useNavigate } from "react-router-dom";

export function CustomLoadingButton(props: LoadingButtonProps) {
  const { className = "", children } = props;
  return (
    <LoadingButton className={className} variant="contained" {...props}>
      {children}
    </LoadingButton>
  );
}
export function CustomLinkButton(props: CustomLinkButtonProps) {
  const { className = "", children } = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate(props.to);
  };
  return (
    <LoadingButton
      {...props}
      className={`custom-button ${className}`}
      onClick={onClick}
    >
      {children}
    </LoadingButton>
  );
}

export function CustomIconButton(props: IconButtonProps) {
  const { className = "", children } = props;
  return (
    <IconButton
      {...props}
      className={className}
      sx={{
        fill: "#969696",
        color: "#969696",
        "&:hover": {
          fill: "white",
          color: "white",
        },
      }}
    >
      {children}
    </IconButton>
  );
}

export function CustomLink(props: CustomLinkProps) {
  const { to } = props;
  return (
    <CustomLinkWrapper {...props} to={to}>
      {props.children}
    </CustomLinkWrapper>
  );
}

const CustomLinkWrapper = styled(Link)(() => ({
  color: "#00A3FF",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));
