import { AppBar, Avatar, Box, IconButton, Toolbar } from "@mui/material";
import HeaderContent from "./header-content";
import { drawerWidth } from "config";
import {
  AlarmOnRounded,
  CalendarMonthRounded,
  MenuOpenRounded,
  MenuRounded,
  SearchRounded,
  CustomIconButton,
  CustomInput,
} from "components/common";

//@ts-ignore
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp.svg";
import useFunctions from "../ts/useFunctions";
import { useEffect } from "react";

export default function Header() {
  const { open, handleDrawerToggle, getUser } = useFunctions();

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <AppBar
      sx={{
        zIndex: 10,
        height: "70px",
        width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
        marginLeft: open ? `${drawerWidth}px` : "auto",
        transitionProperty: "all",
        transitionDuration: "225ms",
        TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
        borderBottom: "1px solid #82aac552",
      }}
      className="sticky"
      position="fixed"
      elevation={0}
    >
      <Toolbar
        sx={{
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          sx={{
            ml: { xs: 0, lg: open ? -2 : 0 },
            transitionProperty: "all",
            transitionDuration: "225ms",
            TransitionTimingFunction: "cubic-bezier(0.4,0,0.6,1)",
          }}
        >
          {!open ? (
            <MenuRounded
              sx={{
                color: "#747474",
              }}
            />
          ) : (
            <MenuOpenRounded
              className="!text-boulder"
              sx={{
                color: "#747474",
              }}
            />
          )}
        </IconButton>
        <Box display={"flex"} gap={3} alignItems={"center"}>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <CustomIconButton>
              <Avatar
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </CustomIconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
