import React from "react";
import { Box, Typography, Divider } from "@mui/material";

interface UploadMediaProps {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
}

const UploadMedia: React.FC<UploadMediaProps> = ({ formData, setFormData }) => {
  const handleThumbnailSelect = (index: number) => {
    const updatedMediaFiles = [...formData.imagesUrl];

    const [selectedFile] = updatedMediaFiles.splice(index, 1);

    updatedMediaFiles.unshift(selectedFile);

    setFormData((prevData: any) => ({
      ...prevData,
      imagesUrl: updatedMediaFiles,
    }));
  };
  console.log(formData, "formData");
  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "90%",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: "40px", textAlign: "center" }}
          component="div"
        >
          Deploy Media
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "#000", textAlign: "center" }}
          component="div"
        >
          By default first image is thumbnail, please select to replace
          thumbnail image and click next to deploy your media files.
        </Typography>
      </Box>

      {/* Display Media Files */}
      {formData.imagesUrl && formData.imagesUrl.length > 0 && (
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "18px", color: "#000" }}
          >
            Media Files
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "14px", color: "#000" }}
          >                Thumbnail Image</Typography>
          <Box
            sx={{
              width: "150px",
              height: "150px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              overflow: "hidden",
              cursor: "pointer",
              marginBottom: '10px'
            }}
          >
            <img
              src={URL.createObjectURL(formData.imagesUrl[0])}
              alt="thumbnail_image"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              maxHeight: "400px",
              overflowY: "auto",
              marginTop: "10px"
            }}
          >
            {formData.imagesUrl.map((file: File, index: number) => (
              <Box
                key={index}
                sx={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
                onClick={() => handleThumbnailSelect(index)}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`media-file-${index}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UploadMedia;
