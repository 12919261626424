// import { toast } from "react-toastify";

// export const SuccessNotification = (message, title) => {
//   toast.success(
//     <div className="flex flex-col">
//       {title && <div className="font-bold">{title}</div>}
//       <div className="text-sm">{message}</div>
//     </div>,
//     {
//       position: "bottom-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     },
//   );
// };
// export const InfoNotification = (message, title) => {
//   toast.info(
//     <div className="flex flex-col">
//       {title && <div className="font-bold">{title}</div>}
//       <div className="text-sm">{message}</div>
//     </div>,
//     {
//       position: "bottom-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     },
//   );
// };
// export const ErrorNotification = (message, title) => {
//   toast.error(
//     <div className="flex flex-col">
//       {title && <div className="font-bold">{title}</div>}
//       <div className="text-sm">{message}</div>
//     </div>,
//     {
//       position: "bottom-right",
//       autoClose: 5000,
//       hideProgressBar: false,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: undefined,
//       theme: "colored",
//     },
//   );
// };

"use client";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { AlertColor } from "@mui/material/Alert";
import CustomSnackbar from "./custom-snackbar";

interface SnackbarContextType {
  showSnackbar: (message: string, severity: AlertColor) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}
interface Notification {
  open: boolean;
  message: string;
  severity: AlertColor;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] = useState<Notification>({
    open: false,
    message: "",
    severity: "info",
  });

  const showSnackbar = useCallback(
    (message: string, severity: AlertColor = "info") => {
      setNotification({ open: true, message, severity });
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      <CustomSnackbar
        notification={notification}
        setNotification={setNotification}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
