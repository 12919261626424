import AuthedLayout from "layouts/authed-layout/AuthedLayout";
import DashboardLayout from "layouts/dashboard-layout/DashboardLayout";
import { useAuthDataState } from "lib/states/authState";

import { Navigate, useRoutes } from "react-router-dom";
import { DashboardPage, NotFoundPage, AgentsPage } from "./lazyRoutes";
import StepperPage from "pages/steppers";
import LoginPage from "pages/auth/login";

export default function Router() {
  const [user] = useAuthDataState();
  const routes = useRoutes([
    {
      element: user.isLoggedIn ? <DashboardLayout /> : <AuthedLayout />,
      children: user.isLoggedIn
        ? [
            { index: true, element: <DashboardPage /> },
            { path: "stepper/:id", element: <StepperPage /> },
            { path: "/agents", element: <AgentsPage /> },
            {
              path: "*",
              element: <Navigate to="/404" replace />,
            },
            {
              path: "/404",
              element: <NotFoundPage />,
            },
          ]
        : [
            { index: true, element: <LoginPage /> },
            {
              path: "*",
              element: <Navigate to="/" replace />,
            },
          ],
    },
  ]);

  return routes;
}
