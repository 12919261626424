import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

interface CustomTableHeaderProps {
  columns: any[];
}

const CustomTableHeader = ({ columns }: CustomTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any) => (
          <TableCell
            key={column.id}
            align={column.align || "center"}
            sx={{ color: "#000", backgroundColor: "#9bc4e0" }}
          >
            {column.headerRender ? column.headerRender() : column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default CustomTableHeader;
