import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ForwardedRef, forwardRef, useEffect } from "react";

import { usePathname } from "hooks/use-pathname";
import { Link } from "react-router-dom";
import { theme } from "theme";

interface ListItemProps {
  url: string;
  target: string;
}

export default function NavItem({ item }: { item: any }) {
  const pathname = usePathname();

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }
  const drawerOpen = true;
  const isSelected = item.url === pathname;

  type AnchorRef = ForwardedRef<HTMLAnchorElement>;
  let listItemProps = {
    component: forwardRef((props, ref: AnchorRef) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    )),
  };

  listItemProps.component.displayName = "ListItemLink";

  const itemHandler = () => {};

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  useEffect(() => {
    if (pathname.includes(item.url)) {
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = "#636363 !important";
  const iconSelectedColor = "#fff";
  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={itemHandler}
      selected={isSelected}
      sx={{
        my: 1,
        pl: 2,
        py: 1.5,
        gap: 1,
        "&:hover": {
          "& svg": {
            fill: "iconSelectedColor",
          },
          "& span": {
            color: iconSelectedColor,
          },
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            fill: textColor,
            "& svg": {
              width: "22px",
              height: "22px",
            },
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}

      <ListItemText
        sx={{
          fontSize: "17px",
        }}
        primary={item.title}
      />
    </ListItemButton>
  );
}
