import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { forwardRef, useState } from "react";
import MaskedInput from "react-text-mask";
import { CustomDatePickerProps, OtherPros } from "utils/interface";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";
import { phoneMask } from "constants/masks";

interface FormInputProps {
  label: string;
  type: "checkbox" | "radio";
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput: React.FC<FormInputProps> = ({
  label,
  type,
  name,
  checked,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={
        type === "checkbox" ? (
          <Checkbox
            sx={{ color: "#15191e" }}
            checked={checked}
            onChange={onChange}
            name={name}
            value={label}
          />
        ) : (
          <Radio
            sx={{ color: "#15191e" }}
            checked={checked}
            onChange={onChange}
            name={name}
            value={label}
          />
        )
      }
      label={label}
    />
  );
};

export function CustomInput(props: TextFieldProps & OtherPros) {
  const { id, className = "", select, sx, ...rest } = props;

  return (
    <TextField
      name={id}
      label={props.label}
      variant="outlined"
      className={className}
      fullWidth
      margin="normal"
      select={select}
      sx={{
        ...sx,
        "& .MuiInputBase-root": {
          height: select ? "48px" : undefined,
          padding: select ? "10px 14px" : undefined,
        },
        "& .MuiOutlinedInput-input": {
          padding: select ? "10px 14px" : undefined,
        },
        "& .MuiInputBase-input": {
          color: "#000",
        },
        "& label": {
          color: "#000 !important",
          top: "-5px !important",
        },
        // "& .MuiOutlinedInput-root": {
        //   border: "1px solid #82aac552",
        // },
      }}
      {...rest}
    />
  );
}
export function CustomPasswordInput(props: TextFieldProps & OtherPros) {
  const { className = "" } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <TextField
      label={props.label}
      className={className}
      fullWidth
      type={showPassword ? "text" : "password"}
      margin="normal"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="start"
            >
              {showPassword ? (
                <VisibilityOff className="stroke-[#fff] mr-4" />
              ) : (
                <Visibility className="stroke-[#fff] mr-4" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  return <MaskedInput {...props} placeholder="" mask={phoneMask} />;
});

TextMaskCustom.displayName = "TextMaskCustom";

export function CustomPhoneNumberInput(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      className="custom-input "
      fullWidth
      margin="normal"
      InputProps={{
        inputComponent: TextMaskCustom as any,
      }}
    />
  );
}

export function CustomDatePicker<TDate extends Moment>(
  props: CustomDatePickerProps<TDate>
) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker {...props} />
    </LocalizationProvider>
  );
}
