import React, { ChangeEvent, useState, useRef } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MediaProps {
  formData: any;
  setFormData: any;
}

const Media: React.FC<MediaProps> = ({ formData, setFormData }) => {
  const [error, setError] = useState<string>("");
  const [dragOver, setDragOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      setFormData((prevData: any) => ({
        ...prevData,
        imagesUrl: [...prevData.imagesUrl, ...newFiles],
      }));
      setError("");
    } else {
      setError("No files selected.");
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    const files = event.dataTransfer.files;
    if (files) {
      const newFiles = Array.from(files);
      setFormData((prevData: any) => ({
        ...prevData,
        imagesUrl: [...prevData.imagesUrl, ...newFiles],
      }));
      setError("");
    } else {
      setError("No files selected.");
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleFileSelect = () => {
    fileInputRef.current?.click();
  };

  const removeFile = (index: number) => {
    setFormData((prevData: any) => ({
      ...prevData,
      imagesUrl: prevData.imagesUrl.filter(
        (_: any, i: number) => i !== index
      ),
    }));
  };

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "90%",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{ fontSize: "40px", textAlign: "center" }}
          component="div"
        >
          Select Media
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#000", textAlign: "center" }}
          component="div"
        >
          Select photos or files to upload from your PC.
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            sx={{
              border: `2px dashed ${dragOver ? "#5fa5ff" : "#ccc"}`,
              borderRadius: "8px",
              padding: "40px 10px",
              width: "70%",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: dragOver ? "#f0f8ff" : "",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "30px",
              }}
            >
              <b>Drag and drop files here or </b>

              <Button
                variant="text"
                component="span"
                sx={{
                  backgroundColor: "#5fa5ff",
                  color: "#fff",
                  width: "fit-content",
                  "&:hover": {
                    backgroundColor: "#5fa5ff",
                  },
                }}
                onClick={handleFileSelect}
              >
                Select from your PC
              </Button>
            </Typography>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
            />
          </Box>

          {formData.imagesUrl.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Selected files:
              </Typography>
              <ul
                style={{
                  color: "#000",
                  paddingLeft: 0,
                  listStyleType: "none",
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                {formData.imagesUrl.map((file: any, index: number) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor: "#8c8f9152",
                      borderRadius: "5px",
                      padding: "5px",
                    }}
                  >
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                      {file.name}
                    </Typography>
                    <IconButton onClick={() => removeFile(index)} size="small">
                      <CloseIcon
                        sx={{ width: "16px", height: "16px", color: "#000" }}
                      />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </Box>
          )}

          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Media;
