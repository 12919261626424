import { Box, Typography } from "@mui/material";
import NavItem from "./NavItem";
import {
  MapsHomeWork as MenuItem0,
  GroupAddRounded as MenuItem1,
  Groups2Rounded as MenuItem2,
  BrandingWatermarkSharp as MenuItem3,
  CategoryRounded as MenuItem4,
  DvrRounded as MenuItem5,
  ReceiptLongRounded as MenuItem6,
  AlignVerticalCenterRounded as MenuItem7,
  LogoutRounded as MenuItem8,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const icons = {
  MenuItem0,
  MenuItem1,
  MenuItem2,
  MenuItem3,
  MenuItem4,
  MenuItem5,
  MenuItem6,
  MenuItem7,
  MenuItem8,
};

const items = [
  {
    id: 0,
    title: "Properties",
    url: "/",
    icon: icons.MenuItem0,
  },
  {
    id: 1,
    title: "Agents",
    url: "/agents",
    icon: icons.MenuItem1,
  },
];

const Navigation = () => {
  const navLinks = items.map((item) => {
    return <NavItem key={item.id} item={item} />;
  });
  const handleLogout = () => {
    localStorage.removeItem("authState");
    window.location.reload();
  };
  return (
    <Box sx={{ px: 2 }}>
      {navLinks}
      <Box
        sx={{
          position: "absolute",
          bottom: 12,
          width: "calc(100% - 16px)",
        }}
      >
        {/* <NavItem
          item={{
            title: "Logout",
            url: "/logout",
            icon: icons.MenuItem8,
            onClick: handleLogout,
          }}
        /> */}
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <MenuItem8 /> Logout
        </Typography>
      </Box>
    </Box>
  );
};

export default Navigation;
