import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CustomTableProps } from "utils/interface";
import { TablePagination } from "@mui/material";
import CustomTableHeader from "./header/CustomTableHeader";

const CustomTable: React.FC<CustomTableProps> = ({ columns, rows }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "calc(100vh - 200px)",
        }}
      >
        <Table stickyHeader>
          <CustomTableHeader columns={columns} />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => (
                <TableRow key={row.code} hover tabIndex={-1}>
                  {columns.map((column: any) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        sx={{ color: "#000" }}
                        key={column.id}
                        align={column.align || "left"}
                        component={column.component || "td"}
                        scope={column.scope || "row"}
                      >
                        {column.render !== undefined
                          ? column.render(row)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{ color: "#000" }}
        rowsPerPageOptions={[100, 500]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default CustomTable;
