import { Box, CircularProgress } from "components/common";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import MainDrawer from "./drawer";
import Header from "./header";
import useFunctions from "./ts/useFunctions";
import { ReactComponent as Logo } from "assets/icons/Logo.svg";
import { Skeleton } from "@mui/material";

export default function DashboardLayout() {
  const { loader, open } = useFunctions();
  return (
    <>
      <Header />
      <MainDrawer />
      <Box
        sx={{
          marginTop: "90px",
          padding: "20px",
          marginLeft: open ? "300px" : "20px",
          marginRight: "20px",
          marginBottom: "20px",
          color: "white",
          bgcolor: "#fff",
          height: "calc(100vh - 150px)",
          borderRadius: "20px",
          transitionProperty: "all",
          transitionDuration: "225ms",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <Suspense>
          <Outlet />
        </Suspense>
        {loader && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            position="absolute"
            top={0}
            left={0}
            bgcolor="#143b51"
            zIndex={100}
          >
            <Skeleton
              width={150}
              style={{
                animation: "1s fadeInOut infinite",
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
