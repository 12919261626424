//@ts-nocheck
import { CustomInput } from "components/common";
import { useFormikContext } from "formik";
import { getFormikValue } from "utils/getFormikValue";
const emailFieldName = "email";
export default function LoginEmailAddress() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, emailFieldName);
  };
  return (
    <CustomInput
      id={emailFieldName}
      name={emailFieldName}
      label="Email Address"
      variant="outlined"
      value={getValue()}
      onChange={handleChange}
      error={errors[emailFieldName] && touched[emailFieldName]}
      helperText={
        errors[emailFieldName] &&
        touched[emailFieldName] &&
        errors[emailFieldName]
      }
    />
  );
}
