// CustomSnackbar.tsx
import React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Fade";
import Alert, { AlertColor } from "@mui/material/Alert";

interface CustomSnackbarProps {
  notification: Notification;
  setNotification: (data: Notification) => void;
}

interface Notification {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({
  notification,
  setNotification,
}) => {
  const handleClose = () => {
    setNotification({ ...notification, open: false });
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const getBackgroundColor = () => {
    switch (notification.severity) {
      case "success":
        return "#069d6e";
      case "error":
        return "#f44336";
      case "warning":
        return "#ff9800";
      case "info":
        return "#2196f3";
      default:
        return "#069d6e";
    }
  };
  return (
    <Snackbar
      open={notification?.open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={handleClose}
      action={action}
      autoHideDuration={3000}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: getBackgroundColor(),
          height: 200,
        },
        "& .MuiPaper-elevation": {
          backgroundColor: getBackgroundColor(),
        },
      }}
    >
      <Alert
        severity={notification?.severity}
        sx={{ width: "100%", color: "#fff" }}
        variant="filled"
        action={action}
        onClose={handleClose}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
