import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField as MuiTextField,
  InputAdornment,
} from "@mui/material";
import { CustomStyledTextField } from "components/common/custom-styled-input";
import { PropertyInfoProps } from "utils/types";

const PropertyInfo: React.FC<PropertyInfoProps> = ({
  formData,
  handleChange,
  errors,
}) => {
  return (
    <Box>
      <Typography variant="body2" sx={{ fontSize: "40px" }} component="div">
        Property Information
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: "30px 0",
          "& .MuiTextField-root": {
            width: {
              xs: "100%",
              sm: "48%",
            },
          },
        }}
      >
        <CustomStyledTextField
          name="name"
          label="Property Name"
          value={formData.name || ""}
          onChange={handleChange}
          fullWidth
          error={errors.name}
          helperText={errors.name ? "Property name is required" : ""}
        />
        <CustomStyledTextField
          name="baths"
          label="Baths"
          value={formData.baths}
          onChange={handleChange}
          fullWidth
          error={errors.baths}
          helperText={errors.baths ? "Baths is required" : ""}
        />
        <CustomStyledTextField
          name="bedrooms"
          label="Bedrooms"
          value={formData.bedrooms}
          onChange={handleChange}
          fullWidth
          error={errors.bedrooms}
          helperText={errors.bedrooms ? "Bedrooms is required" : ""}
        />
        <CustomStyledTextField
          name="parking_areas"
          label="Parking Areas"
          value={formData.parking_areas}
          onChange={handleChange}
          fullWidth
          error={errors.parking_areas}
          helperText={errors.parking_areas ? "Parking areas are required" : ""}
        />
        <CustomStyledTextField
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "12px 16px 12px 0",
            },
          }}
          name="price"
          label="Price"
          value={formData.price || ""}
          onChange={handleChange}
          fullWidth
          error={errors.price}
          helperText={errors.price ? "Price is required" : ""}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="pool"
              checked={formData.pool || false}
              onChange={handleChange}
            />
          }
          label="Pool"
        />
        <MuiTextField
          name="description"
          label="Description"
          value={formData.description || ""}
          onChange={handleChange}
          multiline
          rows={4}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: "1px solid #82aac552",
              color: "#000",
              "&:hover fieldset": {
                borderColor: "#82aac552",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#82aac552",
              },
            },
            "& .MuiFormLabel-root": {
              color: "#000",
              "&.Mui-focused": {
                color: "#000",
              },
            },
          }}
          fullWidth
          error={errors.description}
          helperText={errors.description ? "Description is required" : ""}
        />
      </Box>
    </Box>
  );
};

export default PropertyInfo;
