import { BorderColor } from "@mui/icons-material";
import {
  PaletteColor,
  PaletteOptions,
  ThemeOptions,
  alpha,
  createTheme,
} from "@mui/material/styles";
// import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";
// import "@mui/x-date-pickers/themeAugmentation";
import "@mui/x-data-grid/themeAugmentation";
// import type {} from '@mui/lab/themeAugmentation';
// import '@mui/lab/themeAugmentation';
const primaryMain = "#9bc4e0";

const shape = {
  borderRadius: 10,
};
const palette: PaletteOptions = {
  mode: "dark",
  primary: {
    main: primaryMain,
    light: alpha(primaryMain, 0.5),
    dark: alpha(primaryMain, 0.9),
  },
  secondary: {
    main: "#f50057",
  },
  background: {
    default: "#fff",
    paper: "#fff",
  },
  divider: "#626262",
  text: {
    primary: "#CFCFCF",
    secondary: "#979797",
  },
};
const themeOptions: ThemeOptions = {
  palette: palette,
  direction: "ltr",
  shape: shape,
  typography: {
    fontFamily: "Circular Std",
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: shape.borderRadius,
          "&:hover": {
            backgroundColor: "#cbe0f4",
          },
          "&.Mui-selected": {
            "& .MuiTypography-root": {
              color: "white",
            },
            backgroundColor: (palette.primary as PaletteColor).main,
            "&:hover": {
              backgroundColor: (palette.primary as PaletteColor).main,
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0,
          "& .MuiTypography-root": {
            color: "#000",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          "& input": {
            padding: "12px 16px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#82aac552 !important",
          },
          "& svg": {
            color: (palette.primary as PaletteColor).main,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&[data-shrink="false"]': {
            transform: "translate(16px, 12px) scale(1) !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: "#000",
          fontSize: "16px",
        },
        body2: {
          color: "#000",
          fontSize: "24px",
          fontWeight: "700",
        },
        subtitle1: {
          color: "#CFCFCF",
        },
        subtitle2: {
          color: "#636363",
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "& .Mui-active": {
            color: "#9bc4e0 !important",
          },
          ".MuiStepLabel-labelContainer": {
            color: "#000 !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          "&.MuiLoadingButton-loading svg": {
            color: (palette.primary as PaletteColor).main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          color: "#000",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
          border: "none",
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "#242424",
          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
              outline: "none",
            },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 24px 24px",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: primaryMain,
        },
      },
    },
  },
};

export const theme = createTheme(themeOptions);
