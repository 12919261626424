import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import { styled } from "@mui/system";

export const CustomStyledTextField = styled(MuiTextField)(({ theme }) => ({
  "& input": {
    color: "#000",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "none",
      WebkitTextFillColor: "#000",
      caretColor: "#000",
      borderRadius: "4px",
      backgroundColor: "transparent",
    },
  },
  "& label": {
    color: "#000 !important",
  },
  "& .MuiOutlinedInput-root": {
    // border: "1px solid red",
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
    },
  },
}));
