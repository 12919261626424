import React from "react";
import { Box, Typography, Divider } from "@mui/material";

interface ReviewProps {
  formData: {
    name?: string;
    baths?: string;
    bedrooms?: string;
    parking_areas?: string;
    pool?: boolean;
    price?: string;
    description: string;
    imagesUrl?: any;
  };
}

const Review: React.FC<ReviewProps> = ({ formData }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="body2" sx={{ fontSize: "40px" }} component="div">
        Review Information
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ fontSize: "18px", color: "#000" }}
        >
          Property Details
        </Typography>
        <Divider />
        <Typography variant="body1">
          <strong>Property Name:</strong> {formData.name}
        </Typography>
        <Typography variant="body1">
          <strong>Baths:</strong> {formData.baths}
        </Typography>
        <Typography variant="body1">
          <strong>Bedrooms:</strong> {formData.bedrooms}
        </Typography>
        <Typography variant="body1">
          <strong>Parking Areas:</strong> {formData.parking_areas}
        </Typography>
        <Typography variant="body1">
          <strong>Price:</strong> {formData.price}
        </Typography>
        <Typography variant="body1">
          <strong>Pool:</strong> {formData.pool ? "Yes" : "No"}
        </Typography>
        <Typography variant="body1">
          <strong>Description:</strong> {formData.description}
        </Typography>
      </Box>

      {formData.imagesUrl && formData.imagesUrl.length > 0 && (
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontSize: "18px", color: "#000" }}
          >
            Media Files
          </Typography>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              maxHeight: "400px",
              overflowY: "auto",
            }}
          >
            {formData.imagesUrl.map((url: any, index: any) => (
              <Box
                key={index}
                sx={{
                  width: "150px",
                  height: "150px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={url} // Use the URL directly
                  alt={`media-file-${index}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Review;
