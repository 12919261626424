// src/components/AuthErrorBoundary.tsx
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useResetAuthDataState } from "lib/states/authState";

interface AuthErrorBoundaryProps {
  children: ReactNode;
}

const AuthErrorBoundary: React.FC<AuthErrorBoundaryProps> = ({ children }) => {
  const resetAuthState = useResetAuthDataState();
  const navigate = useNavigate();

  const handleAuthError = () => {
    resetAuthState();
    navigate("/");
  };

  useEffect(() => {
    const handleUnauthorizedError = () => {
      handleAuthError();
    };

    window.addEventListener("unauthorized", handleUnauthorizedError);

    return () => {
      window.removeEventListener("unauthorized", handleUnauthorizedError);
    };
  }, [handleAuthError]);

  return <>{children}</>;
};

export default AuthErrorBoundary;
