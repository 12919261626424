//@ts-nocheck
import { CustomPasswordInput } from "components/common";
import { useFormikContext } from "formik";
import { getFormikValue } from "utils/getFormikValue";
const passwordFieldName = "password";
export default function LoginPassword() {
  const { handleChange, values, errors, touched } = useFormikContext();
  const getValue = () => {
    return getFormikValue(values, passwordFieldName);
  };
  return (
    <CustomPasswordInput
      id={passwordFieldName}
      name={passwordFieldName}
      label="Password"
      value={getValue()}
      onChange={handleChange}
      error={errors[passwordFieldName] && touched[passwordFieldName]}
      helperText={
        errors[passwordFieldName] &&
        touched[passwordFieldName] &&
        errors[passwordFieldName]
      }
    />
  );
}
