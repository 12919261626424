import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Publish: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50%",
        p: 3,
        textAlign: "center",
        gap: 2,
      }}
    >
      <Typography
        variant="h4"
        component="div"
        sx={{ fontWeight: "bold", color: "#000" }}
      >
        You're Almost There!
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        Your property listing is complete. Click the finish button to publish
        your listing and make it live for everyone to see.
      </Typography>
    </Box>
  );
};

export default Publish;
